<template>
    <b-card-code title="Users" no-body>
        <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
                <b-form-group abel-cols="6" label-align="left" label-for="sortBySelect">
                    <h5>Sort</h5>
                    <b-input-group>
                        <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                            <template #first>
                                <option value="">none</option>
                            </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" :disabled="!sortBy">
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <b-form-group v-if="$can('create', 'AddUser')">
                    <h5>Add</h5>
                    <b-input-group>
                        <b-button variant="primary" to="/users/create" class="btn-icon">
                            <feather-icon icon="PlusCircleIcon" /> New User
                        </b-button>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-card-body>

        <b-table borderless striped hover responsive class="position-relative" :per-page="perPage"
            :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
            <template #cell(Modify)="data" v-if="$can('edit', 'EditUser')">
                <b-button variant="flat-primary" :to="{ name: 'edit-user', params: { id: data.item.ID_user } }"
                    v-b-modal.modifyEvent class="btn-icon" size="sm">
                    <feather-icon icon="EditIcon" size="24" />
                </b-button>
            </template>
            <template #cell(Active)="data">
                <b-badge :variant="activeLabel[1][data.value]">
                    {{ activeLabel[0][data.value] }}
                </b-badge>
            </template>
            <template #cell(active_directory)="data">
                <b-badge class="badge-glow" :variant="activeDirectoryLabel[1][data.value]">
                    {{ activeDirectoryLabel[0][data.value] }}
                </b-badge>
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <b-form-group abel-cols="6" label-align="left" label-for="sortBySelect">
                <h5>Per Page</h5>
                <b-form-select id="perPageSelect" v-model="perPage" inline :options="pageOptions" />
            </b-form-group>

            <div>
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                    prev-class="prev-item" next-class="next-item" class="mb-0">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody, BBadge } from "bootstrap-vue";
import axios from "@/libs/axios";

export default {
    components: {
        BCardCode,

        BTable,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BButton,
        BCardBody,
        BBadge,
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
    },
    methods: {},
    data() {
        return {
            userData: '',
            perPage: 50,
            pageOptions: [50, 100, 200],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            fields: [
                { "class": "colModify", key: "Modify", label: "" },
                { key: "ID_user", label: "Id", sortable: true },
                { key: "User_name", label: "Name", sortable: true },
                { key: "Comments", label: "Comments", sortable: true },
                { key: "ID_user_group", label: "User Group", sortable: true },
                { key: "Active", label: "Active", sortable: true },
                { key: "Sec_session", label: "Sec Session", sortable: true },
                { key: "active_directory", label: "Active Directory", sortable: true },
                { key: "Aforo_main", label: "Aforo Main", sortable: true },
                { key: "Aforo_permit", label: "Aforo Permit", sortable: true },
            ],
            items: [],
            activeLabel: [
                {
                    false: "Inactive",
                    true: "Active",
                },
                {
                    false: "light-danger",
                    true: "light-success",
                },
            ],
            activeDirectoryLabel: [
                {
                    false: "False",
                    true: "True",
                },
                {
                    false: "light-danger",
                    true: "light-success",
                },
            ],
        };
    },
    async created() {
        this.userData = JSON.parse(localStorage.getItem('userData'))
        await axios
            .get("/user")
            .then((results) => {
                this.items = results.data;
                this.totalRows = this.items.length;
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
    },
    mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
};
</script>
<style>
.colModify {
    width: 0px;
}
</style>